body {
  margin: 0;
  font-family: "GillroyOffice", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100%;
  display: flex;
  width: 100%;
}

div {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  width: 100%;
  height: 100%;
  background-color: #e9e5de;
  font-size: 14px;
}

html {
  overflow-y: scroll !important;
}

a:visited {
  color: #204131;
}

a {
  font-weight: 600;
  color: #68a17c;
  transition: color 0.05s ease-in-out;
}

a:hover {
  color: #86b496;
}

/**
LEOSpinner
*/

.leo-spinner {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;

  transform: scale(0.3, 0.3);
  transform-origin: 0 0;
}
.leo-spinner div {
  animation: leo-spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.leo-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #86b496;
  opacity: 1;
  margin: -4px 0 0 -4px;
}
.leo-spinner.leo-spinner--light div:after {
  background-color: #fff;
  opacity: 0.3;
}
.leo-spinner div:nth-child(1) {
  animation-delay: -0.036s;
}
.leo-spinner div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.leo-spinner div:nth-child(2) {
  animation-delay: -0.072s;
}
.leo-spinner div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.leo-spinner div:nth-child(3) {
  animation-delay: -0.108s;
}
.leo-spinner div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.leo-spinner div:nth-child(4) {
  animation-delay: -0.144s;
}
.leo-spinner div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.leo-spinner div:nth-child(5) {
  animation-delay: -0.18s;
}
.leo-spinner div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.leo-spinner div:nth-child(6) {
  animation-delay: -0.216s;
}
.leo-spinner div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.leo-spinner div:nth-child(7) {
  animation-delay: -0.252s;
}
.leo-spinner div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.leo-spinner div:nth-child(8) {
  animation-delay: -0.288s;
}
.leo-spinner div:nth-child(8):after {
  top: 56px;
  left: 12px;
  width: 13px;
  height: 13px;
  opacity: 1;
  background-color: #ff5000;
}
@keyframes leo-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
